import styled from '@emotion/styled'

import { TEST_IDS } from '@microcomponents/close-button/test/constants'

import { bool, func, InferProps, string } from 'prop-types'

function CloseButton({ e2eId, onClick, relative, className }: InferProps<typeof CloseButton.propTypes>): JSX.Element {
  return (
    <Wrapper className={className} relative={relative} data-e2eid={e2eId || TEST_IDS.CLOSE_BUTTON} onClick={onClick}>
      <CloseIcon />
    </Wrapper>
  )
}
CloseButton.propTypes = {
  e2eId: string,
  onClick: func.isRequired,
  relative: bool,
  className: string
}

export default CloseButton

const CloseIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="m7.05025 7.04936 9.89945 9.89954m-9.89945 0 9.89945-9.89954" stroke="#000" strokeMiterlimit="16" />
  </svg>
)

const Wrapper = styled.button<{ relative?: boolean }>`
  appearance: none;
  background: none;
  position: ${(props) => (props.relative ? 'relative' : 'absolute')};
  top: 0;
  right: 0;
  z-index: 12;
  cursor: pointer;
  padding: 16px;
  margin: 0;
  line-height: 1;
  & > svg {
    width: 30px;
    height: 30px;
  }
`
