import { Fragment, PureComponent } from 'react'

import FastDrawer from '@eaze/react-fast-drawer'
import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import document from 'global/document'

import CloseButton from '@/stories/buttons/close-button'
import { lightTheme } from '@/theme'
import { TEST_IDS } from '@microcomponents/drawer/test/constants'

import { bool, func, node, object, string } from 'prop-types'

// Pass in useWideModal if you want the wide modal we use for address modal
// Pass showCloseButton to render the default close button
// Pass in headerTitle to display a header
export default class EazeDrawer extends PureComponent {
  static propTypes = {
    useWideModal: bool,
    modalElementClass: object,
    showCloseButton: bool,
    relative: bool,
    onRequestClose: func,
    useModal: bool,
    children: node,
    containerElementClass: object,
    headerTitle: string,
    headerButtonText: string,
    headerButtonOnClick: func,
    headerButtonDisabled: bool
  }

  static defaultProps = {
    useWideModal: false,
    modalElementClass: {},
    showCloseButton: false,
    relative: false,
    onRequestClose: () => {}
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
  }

  renderCloseButton({ showCloseButton, onRequestClose, open, relative }) {
    document.body.style.overflow = open ? 'hidden' : ''

    return showCloseButton ? (
      <CloseButton relative={relative} e2eId={TEST_IDS.CLOSE_BUTTON} onClick={onRequestClose} />
    ) : null
  }

  render() {
    const {
      children,
      containerElementClass,
      modalElementClass,
      useModal,
      useWideModal,
      headerTitle,
      headerButtonText,
      headerButtonOnClick,
      headerButtonDisabled
    } = this.props

    const modalElementClasses = [
      useModal && modalCss,
      useWideModal && wideModalCss,
      modalElementClass && modalElementClass
    ]

    return (
      <ClassNames>
        {({ css }) => (
          <FastDrawer
            {...this.props}
            containerElementClass={css(containerElementClass)}
            modalElementClass={css(modalElementClasses)}
            /* We have to use `id` here b/c drag drawer won't pass data attributes */
            id={TEST_IDS.OVERLAY}>
            {headerTitle ? (
              <ModalHeader>
                <ButtonContainer>
                  {headerButtonText && (
                    <ModalHeaderButton
                      onClick={headerButtonOnClick}
                      disabled={headerButtonDisabled}
                      data-e2eid={TEST_IDS.HEADER_BUTTON}>
                      {headerButtonText}
                    </ModalHeaderButton>
                  )}
                </ButtonContainer>
                <ModalHeaderTitle data-e2eid={TEST_IDS.TITLE}>{headerTitle}</ModalHeaderTitle>
                <ButtonContainer>{this.renderCloseButton({ ...this.props, relative: true })}</ButtonContainer>
              </ModalHeader>
            ) : (
              <Fragment>{this.renderCloseButton({ ...this.props })}</Fragment>
            )}
            {children}
          </FastDrawer>
        )}
      </ClassNames>
    )
  }
}

const ButtonContainer = styled.div`
  width: 64px;
`

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const ModalHeaderTitle = styled.h2`
  color: #000000;
  font-size: 18px;
  flex: 1;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.font.npBold};
`

const ModalHeaderButton = styled.button`
  cursor: pointer;
  width: auto;
  padding: 18px;
  background: transparent;
  border: none;
  color: ${(props) => (props.disabled ? props.theme.colors.textDisabled : props.theme.colors.danger)};
  font-family: ${({ theme }) => theme.typography.font.sim};
  font-size: ${({ theme }) => theme.typography.size.desktop.body3}px;
  text-transform: uppercase;
  &:disabled {
    visibility: hidden;
  }
`

const wideModalCss = css`
  outline: none;
  background: ${lightTheme.colors.white};
  font-size: ${lightTheme.typography.size.desktop.body2}px;
  z-index: 99;
  width: 720px;
  min-height: 47rem;
  border-top-left-radius: ${lightTheme.border.borderRadius.default}px;
  border-top-right-radius: ${lightTheme.border.borderRadius.default}px;
  will-change: transform;

  @media (max-width: 720px) {
    width: 100%;
    max-width: 100%;
    margin-top: 6rem;
    height: 100%;
    margin-bottom: 0;
  }
`

/* used in taxes-and-fees/modal */
const modalCss = css`
  outline: none;
  background: ${lightTheme.colors.white};
  font-size: ${lightTheme.typography.size.desktop.body2}px;
  z-index: 99;
  width: 35rem;
  padding: 4rem;
  border-radius: ${lightTheme.border.borderRadius.default}px;
  will-change: transform;

  @media (max-width: 991px) and (min-width: 560px) {
    width: 42rem;
    margin: 4rem auto 0;
  }

  @media (max-width: 559px) {
    width: 100%;
    max-width: 100%;
    margin-top: 6rem;
    height: 100%;
    margin-bottom: 0;
  }
`
